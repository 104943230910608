//TODO: clean up this mess of a package
// 1. Split out most of the search functionality into separate services
//    a. Sort + Query should be together
//    b. The query builder should be a separate component (and should support async actions)
//        i. It should provide the input bar, and needs access to the job, because it may add cases/controls
//        ii. It should expose a function to add cases/controls through a separate service, which will be re-used
//            for the toolbar button trigger of that (the default trigger will be typing cases and not having cases entered)

function ElasticSearchController(
  $log,
  $window,
  $location,
  $http,
  $q,
  $anchorScroll,
  $mdDialog,
  $document,
  _,
  SETTINGS,
  userProfile,
  searchFields,
  jStat
) {
  this.profile = userProfile;

  // Not sure why cannot inject numeral using constant in config.js
  const { numeral } = $window;
  let fuzzy;

  this.isArray = Array.isArray;

  this.currentQuery = null;
  this.transformedQuery = null;
  this.lastCompletedQuery = null;
  this.currentQueryCompressed = null;

  const _clearQueries = () => {
    this.transformedQuery = null;
    this.lastCompletedQuery = null;
    this.currentQuery = null;
    this.currentQueryCompressed = null;
  };

  this.didYouMean = {};
  this.didYouMeanOrder = [];

  this.fields = "";

  this.mapping = null;

  this.indexName = null;
  this.type = null;

  this.queryResults = [];
  this.queryResultsUnique = [];

  this.allToggledAggregations = {};

  this.queryResultsAggregation = null;

  this.sortName = null;
  this.reverse = null;

  this.sortCounts = {};
  // this.keyboardShortcutFilter = keyboardShortcut;

  // TODO: get from result
  this.assembly = null;

  this.foundLastPage = false;

  // How much to search
  this.recordsToFetch = 10;
  // Where to start the search from
  this.fromRecord = 0;
  // Did we find the last record
  this.foundLastPage = false;
  // Let the user know what page we're on
  this.page = 0;
  this.pages = 0;

  // Let the view layer know when something has been searched AND no results found
  this.noResults = false;

  this.searching = false;

  this.expandAllCards = true;

  this.sortQueries = [];
  this.searchErr = "";

  // due to db versioning
  // TODO: better solution, based on the mapping we have
  this.exactName2Field = "refSeq.name2";

  // Todo: use a default list, or pass a sortable fields config hash, or
  // and maybe this is smarter, fetch the mapping?
  // These are keywords
  // These will be merged with raw fields and
  this.sortableFields = [];

  this.synonyms = null;
  this.synonymsLowerCase = null;

  // Includes the sub-mappings (different mappings of same field)
  // so wil include both (field1 and field1.exact)
  // allows to test whether exact value no matter what user enters
  this.allExactSearchFields = {};
  // the minimal representation, without the '.exact' field value
  this.allExactSearchFieldsMinimal = {};
  this.orderedExactSearchFieldsMinimal = [];

  this.filterThresholds = {};
  this.filters = [];
  this.filtersChecked = {};

  // Newer mappings have a trTv field, that simplifies trTv calculations
  this.hasTrTvField = false;

  this.pipeline = [];

  let lowerCaseFields = {};
  let parentLowerCaseFields = {};
  // Records to fetch

  let sortModes = {};

  let indexConfig = {};
  let explain = 0;

  this.fieldsToSearch = [];

  this.defaultSearchFields = [];
  this.defaultSearchFieldsWithBoost = [];

  this.allToggledAggregations = {};
  this.allSearchFields = {};

  this.parentFields = {};
  this.numericalFields = {};
  this.booleanFields = {};

  const _clearState = () => {
    this.didYouMean = {};
    this.didYouMeanOrder = [];

    this.fields = "";

    this.mapping = null;

    this.indexName = null;
    this.type = null;

    this.queryResults = [];
    this.queryResultsUnique = [];

    this.allToggledAggregations = {};

    this.queryResultsAggregation = null;

    this.sortName = null;
    this.reverse = null;

    this.sortCounts = {};
    // this.keyboardShortcutFilter = keyboardShortcut;

    // TODO: get from result
    this.assembly = null;

    this.foundLastPage = false;

    // How much to search
    this.recordsToFetch = 10;
    // Where to start the search from
    this.fromRecord = 0;
    // Did we find the last record
    this.foundLastPage = false;
    // Let the user know what page we're on
    this.page = 0;
    this.pages = 0;

    // Let the view layer know when something has been searched AND no results found
    this.noResults = false;

    this.searching = false;

    this.expandAllCards = true;

    this.sortQueries = [];
    this.searchErr = "";

    // due to db versioning
    // TODO: better solution, based on the mapping we have
    this.exactName2Field = "refSeq.name2";

    // Todo: use a default list, or pass a sortable fields config hash, or
    // and maybe this is smarter, fetch the mapping?
    // These are keywords
    // These will be merged with raw fields and
    this.sortableFields = [];

    this.synonyms = null;
    this.synonymsLowerCase = null;

    // Includes the sub-mappings (different mappings of same field)
    // so wil include both (field1 and field1.exact)
    // allows to test whether exact value no matter what user enters
    this.allExactSearchFields = {};
    // the minimal representation, without the '.exact' field value
    this.allExactSearchFieldsMinimal = {};
    this.orderedExactSearchFieldsMinimal = [];

    this.filterThresholds = {};
    this.filters = [];
    this.filtersChecked = {};

    // Newer mappings have a trTv field, that simplifies trTv calculations
    this.hasTrTvField = false;

    this.pipeline = [];
    //
    lowerCaseFields = {};
    parentLowerCaseFields = {};
    // Records to fetch

    sortModes = {};

    indexConfig = {};
    explain = 0;

    this.fieldsToSearch = [];

    this.defaultSearchFields = [];
    this.defaultSearchFieldsWithBoost = [];

    this.allToggledAggregations = {};
    this.allSearchFields = {};

    this.parentFields = {};
    this.numericalFields = {};
    this.booleanFields = {};
  };

  const defaultOperator = "AND";

  const settings = {
    // Lenient true completely breaks multi-field search
    // returns records when 0 matches
    lenient: true,
    phrase_slop: 5,
    // minimum_should_match: "0%",
    // auto_generate_phrase_queries: true,
    // split_on_whitespace: true,
    // multiple matchd clauses get ranked higher by 30%
    tie_breaker: 0.3
    // split_queries_on_whitespace: true
    // flags: 'ALL'
    // default_field: '_all',
  };

  // TODO: Get these from config file
  const valueDelimiter = " ; ";
  const positionDelimiter = " | ";
  const alleleDelimiter = " / ";
  const emptyFieldChar = "!";

  this.emptyFieldChar = emptyFieldChar;

  let queryCanceler = $q.defer();

  this.$onDestroy = () => {
    //cancel any in-progress query
    //avoids potential issues if we navigate away from component
    //then back, and re-query before previous query resolved
    //most apparent if http interceptor buffers during offline use & resolves
    //after connection re-established
    queryCanceler.resolve();
  };

  this.scrollTo = id => {
    $location.hash(id);
    $anchorScroll();
  };

  this.onPipelineUpdate = pipeline => {
    this.pipeline = [].concat(pipeline);
  };

  this.filter = (fieldName, val, exclude) => {
    let found = false;

    this.filters.forEach((iVal, idx) => {
      if (iVal[0] === fieldName && iVal[1] === val) {
        found = true;
        this.filters.splice(idx, 1);
      }
    });

    if (!found) {
      const hasSpace = typeof val === "string" && val.indexOf(" ") > -1;
      this.filters.push([fieldName, val, !!exclude, hasSpace]);
    }

    this.searchInput(null, null, null, null, null, false, true);
  };

  this.showSynonymDialog = ev =>
    $mdDialog.show({
      controller: function DialogController($scope, existingSynonyms) {
        // $log.debug('existingSynonyms are', existingSynonyms);

        if (existingSynonyms && Object.keys(existingSynonyms).length) {
          $scope.synonymsArray = [];

          Object.keys(existingSynonyms).forEach(name => {
            if (Array.isArray(existingSynonyms[name])) {
              $scope.synonymsArray.push({
                name,
                value: existingSynonyms[name].join("\n")
              });
            } else {
              $scope.synonymsArray.push({
                name,
                value: existingSynonyms[name]
              });
            }
          });
        } else {
          $scope.synonymsArray = [
            {
              name: "",
              value: ""
            }
          ];
        }

        $scope.addSynonymToArray = function () {
          $scope.synonymsArray.push({
            name: "",
            value: ""
          });
        };

        $scope.removeSynonym = function () {
          $scope.synonymsArray.pop();
        };

        $scope.attachCasesAndControls = function () {
          const synonyms = {};

          $scope.synonymsArray.forEach(synonym => {
            // $log.debug('synonym', synonym);
            if (synonym.name.match(/^s+$/)) {
              return;
            }

            let synonymValue;
            if (typeof synonym.value === "string") {
              if (synonym.value.match(/^\s+$/)) {
                return;
              }

              synonymValue = synonym.value
                .split(/[\r\n]/)
                .map(val => val.trim());
            } else {
              synonymValue = synonym.value;
            }

            synonyms[synonym.name.trim()] = synonymValue;
          });

          // $log.debug('after transform, synonyms are', synonyms);
          $mdDialog.hide(synonyms);
        };

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      },
      templateUrl: "jobs/results/search/jobs.results.search.synonyms.tpl.html",
      parent: angular.element($document[0].body),
      locals: {
        existingSynonyms: this.synonyms
      },
      targetEvent: ev,
      clickOutsideToClose: true
    });

  this.setCaseControls = () => {
    this.showSynonymDialog().then(synonyms => {
      if (!Object.keys(synonyms).length) {
        return;
      }

      this.synonyms = Object.assign({}, synonyms);

      this.synonymsLowerCase = {};

      Object.keys(this.synonyms).forEach(name => {
        this.synonymsLowerCase[name.toLowerCase()] = this.synonyms[name];
      });

      $http
        .post(
          `${SETTINGS.apiEndpoint}jobs/${this.jobResource._id}/addSynonyms`,
          {
            synonyms: this.synonyms
          }
        )
        .then(() => this.searchInput(this.query));
    });
  };

  this.showCard = ($event, index) => {
    // Don't show the full card if the user just wanted to go to the link
    if ($event.target.href) {
      return;
    }

    this.showFullCard = this.queryResults[index];
  };

  this.isExonic = result => result["refSeq.siteType"].includes("exonic");

  this.getAllSortCounts = () => {
    if (this.sortQueries.length === 0) {
      this.sortCounts = {};
    }

    this.sortQueries.forEach(object => {
      const sortName = Object.keys(object)[0];

      if (object[sortName].order === "asc") {
        this.sortCounts[sortName] = 2;
      } else if (object[sortName].order === "desc") {
        this.sortCounts[sortName] = 1;
      }
    });
  };

  // TODO: combine all NCBI link makes, differe only by path
  this.makeRefSeqLink = searchFields.makeRefSeqLink;

  this.makeDbSnpLink = searchFields.makeDbSnpLink;

  //strangely enough, if this is named this.sort, it won't work
  //in angular's template
  this.sortResults = sortName => {
    this.page = 0;
    this.foundLastPage = false;

    let hasSort;
    let sortNameCount = 0;

    const origSortName = sortName;

    //scripted fields cannot be submitted in an array...
    //or be named I believe, at least not by the same name
    //as the real sort field ... figure this out
    if (sortName === "chrom") {
      sortName = "_script";
    }

    if (this.sortQueries.length > 0) {
      this.sortQueries.forEach((object, index) => {
        if (object.hasOwnProperty(sortName)) {
          hasSort = true;

          if (object[sortName].order === "asc") {
            this.sortQueries.splice(index, 1);
          } else if (object[sortName].order === "desc") {
            object[sortName].order = "asc";
            sortNameCount = 2;
          }
        }
      });
    }

    if (!hasSort) {
      const sort = {};

      // TODO: make less fragile
      if (origSortName === "chrom") {
        sort[sortName] = {
          script: {
            source:
              "def val = doc['chrom'].value.substring(3); if(val == 'x' || val == 'X'){return 23;}else if(val == 'y' || val == 'Y'){return 24;} else if(val == 'm' || val == 'M' || val == 'mt' || val == 'MT'){return 25;} else { return Integer.parseInt(val) }"
          },
          type: "number"
        };
      } else {
        sort[sortName] = {};
      }

      sort[sortName].order = "desc";

      if (sortModes[sortName]) {
        sort[sortName].mode = sortModes[sortName];
      }

      this.sortQueries.push(sort);

      sortNameCount = 1;
    }

    this.sortCounts[origSortName] = sortNameCount;

    this.fromRecord = 0;
    this.page = 0;
    this.foundLastPage = false;
    this.searchInput(false, false, false, false, false, false, true);

    return sortNameCount;
  };

  this.makePhenotypeIDlinks = searchFields.makePhenotypeIDlinks;

  const transversions = {
    AT: 1,
    TA: 1,
    GT: 1,
    TG: 1,
    AC: 1,
    CA: 1,
    CG: 1,
    GC: 1
  };

  const transitions = {
    AG: 1,
    GA: 1,
    TC: 1,
    CT: 1
  };

  const _makeDidYouMeanString = (
    transformedQuery,
    didYouMean,
    didYouMeanOrder
  ) => {
    let newQuery = transformedQuery;

    didYouMeanOrder.forEach(queryPart => {
      const re = new RegExp(`(${addslashes(queryPart)})`, "gi");
      newQuery = newQuery.replace(
        re,
        () => (didYouMean[queryPart] ? didYouMean[queryPart][0][1] : "")
      );
    });

    return newQuery;
  };

  this.updateSize = (size = 10) => {
    this.recordsToFetch = size;

    this.searchInput(null, null, null, null, 0);
  };

  this.updateQueryString = (newQuery, append) => {
    if (append) {
      this.query = `${newQuery} ${this.query}`;
    } else {
      this.query = newQuery;
    }

    this.searchInput(this.query);
    this.onSuggested({
      queryWithSuggestion: this.query
    });
    // this.didYouMean = {};
    // this.didYouMeanOrder = [];
  };

  this.getClinvarSize = (end, start) => {
    const startArr = start.split(valueDelimiter);
    const endArr = end.split(valueDelimiter);
    return startArr
      .map((val, idx) => (endArr[idx] - val).toLocaleString())
      .join(` bp ${valueDelimiter}`);
  };

  this.searchInput = (
    newQuery,
    previous,
    next,
    toPage,
    from,
    scroll = true,
    hasFilter = false,
    filterAgg = null
  ) => {
    this.showFullCard = false;
    this.searching = true;

    let trulyNew =
      hasFilter ||
      previous ||
      next ||
      (toPage || toPage === 0) ||
      (from || from === 0);

    if (newQuery) {
      this.didYouMeanString = null;
      this.didYouMean = {};
      this.didYouMeanOrder = [];

      [this.transformedQuery, this.fieldsToSearch] =
        _makeQueryFromString.call(this, newQuery) || "*";

      trulyNew = trulyNew || this.transformedQuery !== this.lastCompletedQuery;

      if (trulyNew) {
        this.currentQuery = newQuery;

        this.onTransform({
          // Don't transform the query in the url...synonym expansion can yield
          // huge urls that lead to webserver errors
          transformedQuery: this.transformedQuery,
          suggestions: this.didYouMean
        });

        if (this.didYouMeanOrder.length) {
          this.didYouMeanString = _makeDidYouMeanString(
            this.transformedQuery,
            this.didYouMean,
            this.didYouMeanOrder
          );
        }
      }
    }

    // Each time we trigger an aggregation, the query is submitted, so process
    // them serially when possible (i.e when filterAgg is submitted to searchInput)
    const aggregations = _formAggregationsBody(
      filterAgg || this.allToggledAggregations
    );

    [this.page, this.fromRecord, this.foundLastPage] = _paginate(
      this.page,
      this.fromRecord,
      this.foundLastPage,
      this.recordsToFetch,
      previous,
      next
    );

    const body = {
      size: this.recordsToFetch,
      from: this.fromRecord,
      query: {
        bool: {
          must: {}
        }
      }
    };

    //Regression in 5.5, stops treating these as match__all
    if (this.transformedQuery === "*") {
      body.query.bool.must = {
        match_all: {}
      };
    } else {
      body.query.bool.must = {
        query_string: {
          default_operator: defaultOperator,
          query: this.transformedQuery
        }
      };

      if (this.fieldsToSearch) {
        body.query.bool.must.query_string.fields = this.fieldsToSearch;
      }

      Object.assign(body.query.bool.must.query_string, settings);
    }

    if (Object.keys(this.filters).length) {
      [body.query.bool.filter, this.filtersChecked] = _formFilterBody(
        this.filters
      );
    } else {
      this.filtersChecked = {};
    }

    // Now done offline
    // if (Object.keys(this.statsFiltersOrder).length) {
    //   if (!body.query.bool.filter) {
    //     body.query.bool.filter = {
    //       bool: {
    //         must: []
    //       }
    //     };
    //   }

    //   for (let filterName in this.statsFiltersOrder) {
    //     if (this.statsFiltersOrder.hasOwnProperty(filterName)) {
    //       const order = this.statsFiltersOrder[filterName];

    //       body.query.bool.filter.bool.must.push(Object.assign({}, this.statsFilters[order]));
    //     }
    //   }
    // }

    if (this.sortQueries.length) {
      body.sort = this.sortQueries;
    }

    this.queryBodyWithoutAggregations = Object.assign({}, body);
    delete this.queryBodyWithoutAggregations.from;
    delete this.queryBodyWithoutAggregations.size;

    if (Object.keys(aggregations).length) {
      body.aggregations = aggregations;
    }

    // body.aggregations.computedChr = {
    //   terms: {
    //     script: {
    //       lang: "painless",
    //       source: 'doc["chrom"].value.substring(3)'
    //     }
    //   }
    // };

    // "script_fields" : {
    //   "test1" : {
    //       "script" : {
    //           "lang": "painless",
    //           "source": "doc['price'].value * 2"
    //       }
    //   },

    this.update({
      q: this.currentQuery,
      sort: this.sortQueries.length ? JSON.stringify(this.sortQueries) : null,
      size: this.recordsToFetch,
      from: this.fromRecord,
      compressed: this.currentQueryCompressed
    });

    queryCanceler.resolve();
    queryCanceler = $q.defer();

    //if this is just an aggregation, set size to 0, speed up, allow elastic to cache
    if (!trulyNew) {
      body.size = 0;
      delete body.from;
    }

    //Don't query model to avoid sending the entire job state (including results)
    $http
      .post(
        `${SETTINGS.apiEndpoint}jobs/${this.jobResource._id}/search`,
        {
          searchBody: body
        },
        {
          timeout: queryCanceler.promise
        }
      )
      .then(response => {
        if (explain) {
          $log.debug("Explain response:", response);
          return;
        }

        this.searching = false;

        const data = response.data.body;

        if (data['_shards'].total !== data['_shards'].successful) {
          throw ({ data: "shards_missing", statusText: "We couldn't find all expected parts of this index. Probably best to re-index", status: 255 });
        }

        this.took = data.took / 1000;
        this.searchErr = "";
        // Can have aggregation only query
        this.addAggregations(data, filterAgg);
        if (!trulyNew) {
          return;
        }
        this.queryResults = [];
        this.queryResultsUnique = [];
        this.hits = data.hits.total;
        if (data && data.hits && data.hits.hits.length) {
          this.noResults = false;
          //Data must be after; because could be aggregation only
          this.data = data;
          if (this.fromRecord + this.recordsToFetch >= data.hits.total) {
            this.foundLastPage = true;
          }
          this.pages = data.hits.total % this.recordsToFetch;

          _fillQueryResultsObject.call(this, data);
          this.results = _flattenSource(data.hits.hits);
          this.resultsRaw = data.hits.hits.map(obj => obj._source);

          this.chi2crit = jStat.chisquare.inv(1 - 0.05 / this.hits, 1);
          this.alpha = 0.05 / this.hits;
        } else {
          this.noResults = true;
          this.queryResultsAggregation = null;
          this.data = null;
          this.chi2crit = null;
          this.alpha = null;
        }
        this.lastCompletedQuery = this.transformedQuery;
        if (scroll) {
          $anchorScroll();
        }
      })
      .catch(errResponse => {
        if (explain) {
          $log.debug("Explain response:", errResponse);
          return;
        }

        if (errResponse.status === -1) {
          // Aborted
          return;
        }

        $log.error(errResponse);
        // TODO: handle
        // $log.debug('http promise is', promise, errResponse);
        this.searchErr = errResponse.statusText;

        if (errResponse.data === "index_not_found_exception") {
          this.onMissing();
        }

        if (errResponse.data === "shards_missing") {
          this.onMissing({ type: "shards_missing" });
        }

        if (newQuery) {
          this.aggregations = [];
        }

        this.queryResults = [];
        this.queryResultsUnique = [];
        this.queryResultsAggregation = null;
        this.noResults = true;

        this.searching = false;
        this.lastCompletedQuery = null;

        if (scroll) {
          $anchorScroll();
        }
      });
  };

  function _paginate(
    page,
    fromRecord,
    foundLastPage,
    recordsToFetch,
    previous = false,
    next = false
  ) {
    let tPage = page;
    let fromR = fromRecord;
    let last = foundLastPage;

    if (previous) {
      tPage -= 1;
      fromR = tPage === 0 ? 0 : tPage * recordsToFetch;
      last = false;
    } else if (next) {
      tPage += 1;
      fromR = tPage === 0 ? 0 : tPage * recordsToFetch;
    }

    return [tPage, fromR, last];
  }

  function _formFilterBody(filters = {}, checkedFilters = {}) {
    const rFilter = {
      bool: {}
    };
    const rCheckedFilters = Object.assign({}, checkedFilters);

    filters.forEach(val => {
      const term = {};
      term[val[0]] = val[1];

      if (!rCheckedFilters[val[0]]) {
        rCheckedFilters[val[0]] = {};
      }

      if (!rCheckedFilters[val[0]][val[1]]) {
        rCheckedFilters[val[0]][val[1]] = {};
      }

      if (rCheckedFilters[val[0]][val[1]][val[2]] === undefined) {
        rCheckedFilters[val[0]][val[1]][val[2]] = true;
      }

      // Exclude or include; true === must_not (exclude)
      if (val[2]) {
        if (!rFilter.bool.must_not) {
          rFilter.bool.must_not = [];
        }
        rFilter.bool.must_not.push({
          term
        });
      } else {
        if (!rFilter.bool.must) {
          rFilter.bool.must = [];
        }
        rFilter.bool.must.push({
          term
        });
      }
    });

    return [rFilter, rCheckedFilters];
  }

  function _flattenSource(results) {
    if (!Array.isArray(results)) {
      return;
    }

    return results.map(result => {
      const rObj = {};

      _flattenTrack(result._source, "", rObj);

      return rObj;
    });
  }

  function _flattenTrack(track, trackPart = "", rObj = {}) {
    // We found the value
    if (Array.isArray(track)) {
      rObj[trackPart] = track;
      return rObj;
    }

    Object.keys(track).forEach(trackName => {
      let name = "";
      if (trackPart) {
        name = trackPart + "." + trackName;
      } else {
        name = trackName;
      }

      _flattenTrack(track[trackName], name, rObj);
    });

    return rObj;
  }

  function _formAggregationsBody(aggsVal = []) {
    const aggs = {};

    if (Object.keys(aggsVal).length) {
      Object.keys(aggsVal).forEach(agg => {
        // TODO: Simplify
        if (agg === "compoundHet" || agg === "homHetRatio") {
          aggs.hetCount = {
            terms: {
              size: aggsVal[agg][0],
              //could use params to tell fields
              field: aggsVal[agg][1][0]
            }
          };

          aggs.homCount = {
            terms: {
              size: aggsVal[agg][0],
              //could use params to tell fields
              field: aggsVal[agg][1][1]
            }
          };
        } else if (agg === "variantHoms") {
          aggs.variantHoms = {
            "terms": {
              "size": aggsVal[agg][0],
              "field": aggsVal[agg][1],
            },
            "aggs": {
              "homs": {
                "sum": {
                  "script": {
                    "source": "doc['homozygotes'].length"
                  },
                },
              }
            }
          };
        } else if (agg === "compoundBases") {
          // the old calculation for trTv
          aggs.compoundBases = {
            terms: {
              // There are only 12 possible combinations, so size of 12 works here
              size: aggsVal[agg][0],
              //could use params to tell fields
              //could use values to get a specific index
              //.value gives first index
              script: `if( doc['alt'].values.size() === 1 && (doc['alt'].value === 'A' ||
              doc['alt'].value === 'C' || doc['alt'].value === 'T' || doc['alt'].value === 'G')) {
                doc['ref'].value + doc['alt'].value
              }`
            }
          };
        } else {
          const types = aggsVal[agg][2] || ["terms"];

          types.forEach(type => {
            const newAgg = {};

            if (type !== "terms") {
              newAgg[type] = {
                field: aggsVal[agg][1]
              };
            } else {
              newAgg[type] = {
                size: aggsVal[agg][0],
                field: aggsVal[agg][1]
              };
            }

            if (type === "terms") {
              aggs[agg] = {};
              aggs[agg][type] = newAgg[type];
            } else {
              aggs[agg + "_" + type] = newAgg;
            }
          });
        }
      });
    }

    return aggs;
  }

  this.addAggregations = data => {
    if (!this.queryResultsAggregation) {
      this.queryResultsAggregation = {};
    }

    // TODO: remove what isn't toggled (noting that need to handle transitions,
    // transversion, trTv, compoundBases, cases, etc)
    // Object.keys(this.queryResultsAggregation).forEach((aggName) => {
    //   if(!this.allToggledAggregations[aggName] && aggName != 'transitions' && ) {
    //     delete this.queryResultsAggregation[aggName];
    //   }
    // });

    if (data.aggregations) {
      Object.assign(this.queryResultsAggregation, data.aggregations);

      if (data.aggregations.compoundBases || data.aggregations.trTv) {
        this.queryResultsAggregation.transitions = 0;
        this.queryResultsAggregation.transversions = 0;
        this.queryResultsAggregation.trTvRatio = Infinity;

        if (data.aggregations.compoundBases) {
          let tr = 0;
          let tv = 0;

          data.aggregations.compoundBases.buckets.forEach(val => {
            if (transitions.hasOwnProperty(val.key.toUpperCase())) {
              tr += val.doc_count;
            } else if (transversions.hasOwnProperty(val.key.toUpperCase())) {
              tv += val.doc_count;
            }
          });

          this.queryResultsAggregation.transitions = tr;
          this.queryResultsAggregation.transversions = tv;
        } else {
          data.aggregations.trTv.buckets.forEach(val => {
            if (val.key === 1) {
              this.queryResultsAggregation.transitions = val.doc_count;
            } else if (val.key === 2) {
              this.queryResultsAggregation.transversions = val.doc_count;
            }
          });
        }

        if (this.queryResultsAggregation.transversions > 0) {
          this.queryResultsAggregation.trTvRatio =
            (this.queryResultsAggregation.transitions || 0) /
            this.queryResultsAggregation.transversions;
        }
      }

      if (!(data.aggregations.homCount && data.aggregations.hetCount)) {
        return;
      }

      //TODO: use a better system here. If homCount set we are calculating samples hom/het ratio
      if (this.allToggledAggregations["compoundHet"]) {
        const sampleCounts = {};
        let totalHits = 0;
        data.aggregations.homCount.buckets.forEach(val => {
          if (!sampleCounts[val.key]) {
            sampleCounts[val.key] = val.doc_count * 2;
          } else {
            sampleCounts[val.key] += val.doc_count * 2;
          }
        });

        data.aggregations.hetCount.buckets.forEach(val => {
          if (!sampleCounts[val.key]) {
            sampleCounts[val.key] = val.doc_count;
          } else {
            sampleCounts[val.key] += val.doc_count;
          }
          totalHits += val.doc_count;
        });

        let cases = 0;
        let caseHits = 0;
        let controlHits = 0;
        let compoundCases = 0;
        let controls = 0;
        let compoundControls = 0;
        const totalCases =
          this.synonymsLowerCase &&
          this.synonymsLowerCase.cases &&
          this.synonymsLowerCase.cases.length;
        const totalControls =
          this.synonymsLowerCase &&
          this.synonymsLowerCase.controls &&
          this.synonymsLowerCase.controls.length;

        if (totalCases) {
          this.casesMap = {};
          this.synonymsLowerCase.cases.forEach(val => {
            this.casesMap[val] = 1;
          });
        }

        if (totalControls) {
          this.controlsMap = {};
          this.synonymsLowerCase.controls.forEach(val => {
            this.controlsMap[val] = 1;
          });
        }

        Object.keys(sampleCounts).forEach(sample => {
          if (totalCases || totalControls) {
            if (totalCases && this.casesMap[sample]) {
              cases++;
              caseHits += sampleCounts[sample];

              if (sampleCounts[sample] > 1) {
                compoundCases++;
              }
            } else if (totalControls && this.controlsMap[sample]) {
              controls++;

              controlHits += sampleCounts[sample];

              if (sampleCounts[sample] > 1) {
                compoundControls++;
              }
            }
          }
        });

        this.queryResultsAggregation.compoundHets = [];

        // sort in descending order by number of alleles
        Object.keys(sampleCounts)
          .sort((a, b) => sampleCounts[b] - sampleCounts[a])
          .forEach(sample => {
            this.queryResultsAggregation.compoundHets.push([
              sample,
              sampleCounts[sample]
            ]);
          });

        if (cases > 0 || controls > 0) {
          this.queryResultsAggregation.caseAlleles = caseHits || 0;
          this.queryResultsAggregation.controlAlleles = controlHits || 0;
          this.queryResultsAggregation.caseControlAlleleProp =
            caseHits / controlHits;
          this.queryResultsAggregation.totalAlleles = totalHits;
          this.queryResultsAggregation.cases = cases || 0;
          this.queryResultsAggregation.compoundCases = compoundCases || 0;
          this.queryResultsAggregation.controls = controls || 0;
          this.queryResultsAggregation.compoundControls = compoundControls || 0;
          this.queryResultsAggregation.totalCases = totalCases || 0;
          this.queryResultsAggregation.totalControls = totalControls || 0;
          this.queryResultsAggregation.totalCasesAndControls =
            totalControls + totalCases || 0;
        }
      }

      if (this.allToggledAggregations["homHetRatio"]) {
        const homHetRatio = [];
        const homCount = {};

        const all = [];
        data.aggregations.homCount.buckets.forEach(val => {
          homCount[val.key] = Number(val.doc_count);
        });

        data.aggregations.hetCount.buckets.forEach(val => {
          const ratio = Number(val.doc_count) / (homCount[val.key] || 0);
          homHetRatio.push([val.key, ratio]);
          all.push(ratio);
        });

        let mean = 0;
        let sd = 0;

        if (all.length) {
          homHetRatio.sort((a, b) => b[1] - a[1]);

          mean = all.reduce((sum, value) => sum + value, 0) / all.length;
          sd = Math.pow(
            all.reduce((sum, value) => sum + Math.pow(value - mean, 2)) /
            (all.length - 1),
            0.5
          );
        }

        this.queryResultsAggregation.homHetRatio = {
          ratio: homHetRatio,
          mean,
          sd
        };
      }
    }
  };

  // take a sample list in form [[sampleName, value1, valueN, ...]] and saves to file
  this.downloadSamples = (samples = [], name = "samples.txt") => {
    const out = [];

    samples.forEach(sampleInfo => {
      const info = sampleInfo.join("\t");

      out.push(info);
    });

    const str = out.join("\n");

    writeFile(str, name);
  };

  this.downloadVariantHoms = (buckets = [], name = "samples.txt") => {
    const str = buckets.map(bucket => `${bucket.key}\t${bucket.homs.value}`).join("\n");

    writeFile(str, name);
  };

  function writeFile(str, name) {
    const element = $document[0].createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(str)
    );
    element.setAttribute("download", name);
    element.target = "_self"; // may be needed for firefox
    element.style.display = "none";
    $document[0].body.appendChild(element);

    element.click();

    $document[0].body.removeChild(element);
  }

  this.toggleAnyAggregation = (name, size = 100) => {
    if (this.allToggledAggregations[name]) {
      delete this.allToggledAggregations[name];
      return;
    }

    // get the field name of the corresponding type: "keyword" field, which is the only that ES can perform terms aggregation on
    const eName =
      this.allExactSearchFields[name] ||
      ((name in this.numericalFields || name in this.booleanFields) && name);

    if (!eName) {
      $log.error("field not found in exact fields", name);
      return;
    }

    const type = ["terms"];

    if (
      name in this.numericalFields &&
      name !== "trTv" &&
      name !== "clinvar.alleleID" &&
      name.indexOf("ensembl") === -1 &&
      name.indexOf("refSeq") === -1
    ) {
      type[0] = "extended_stats";

      if (name !== "pos") {
        type[1] = "percentiles";
      }
    }

    // Last position of allToggledAggregations holds the exact version of the submitted name
    // Allows interface to not worry about presence of ".exact"
    // The aggregation results will be held under [name] rather than [eName]
    // Can result in duplicate aggregations with unexpected consequences
    this.allToggledAggregations[name] = [size, eName, type];

    const agg = {};
    agg[name] = this.allToggledAggregations[name];

    this.searchInput(null, null, null, null, null, false, false, agg);
  };

  // A special aggregation; for compound hets
  // Mimics the toggleAnyAggregation return values
  this.toggleCompoundHetAggregation = () => {
    if (this.allToggledAggregations["compoundHet"]) {
      delete this.allToggledAggregations["compoundHet"];
      return;
    }

    if (!this.numSamples) {
      $log.warn(
        "Tried to toggle compoundHetAggregation with 0 samples in annotation"
      );
      return;
    }

    const eNameHet = this.allExactSearchFields["heterozygotes"];
    const eNameHom = this.allExactSearchFields["homozygotes"];

    this.allToggledAggregations["compoundHet"] = [
      this.numSamples,
      [eNameHet, eNameHom]
    ];

    this.searchInput(null, null, null, null, null, false, false, {
      compoundHet: this.allToggledAggregations["compoundHet"]
    });
  };

  this.toggleHomHetRatioAggregation = () => {
    if (this.allToggledAggregations["homHetRatio"]) {
      delete this.allToggledAggregations["homHetRatio"];
      return;
    }

    if (!this.numSamples) {
      $log.warn(
        "Tried to toggle toggleHomHetRatioAggregation with 0 samples in annotation"
      );
      return;
    }

    const eNameHet = this.allExactSearchFields["heterozygotes"];
    const eNameHom = this.allExactSearchFields["homozygotes"];

    this.allToggledAggregations["homHetRatio"] = [
      this.numSamples,
      [eNameHet, eNameHom]
    ];

    this.searchInput(null, null, null, null, null, false, false, {
      homHetRatio: this.allToggledAggregations["homHetRatio"]
    });
  };

  this.toggleVariantHomsAggregation = noQuery => {
    if (this.allToggledAggregations["variantHoms"]) {
      delete this.allToggledAggregations["variantHoms"];
      return;
    }

    const agg = {};
    this.allToggledAggregations["variantHoms"] = [10e6, "gnomad.genomes.id"];
    agg.variantHoms = this.allToggledAggregations["variantHoms"];

    if (!noQuery) {
      this.searchInput(null, null, null, null, null, false, false, agg);
    }
  };

  this.toggleTrTvAggregation = noQuery => {
    if (this.allToggledAggregations["trTv"]) {
      delete this.allToggledAggregations["trTv"];
      return;
    }

    if (this.allToggledAggregations["compoundBases"]) {
      delete this.allToggledAggregations["compoundBases"];
      return;
    }

    const agg = {};
    if (!this.hasTrTvField) {
      this.allToggledAggregations["compoundBases"] = [12];
      agg.compoundBases = this.allToggledAggregations["compoundBases"];
    } else {
      this.allToggledAggregations["trTv"] = [3, "trTv"];
      agg.trTv = this.allToggledAggregations["trTv"];
    }

    if (!noQuery) {
      this.searchInput(null, null, null, null, null, false, false, agg);
    }
  };

  this.$onChanges = changedObjects => {
    if (changedObjects.jobResource) {
      _clearQueries();
      _clearState();

      if (changedObjects.jobResource.currentValue) {
        this.numSamples = this.jobResource.getNumSamples();

        // copy the array
        this.mapping = changedObjects.jobResource.currentValue.search.fieldNames.slice(
          0
        );

        // Will ALWAYS give results for any darned query
        // TODO: have a boolean type category in search config file, exclude all boolean fields

        // $log.debug('lowerCaseFields', lowerCaseFields, parentLowerCaseFields);
        this.indexName =
          changedObjects.jobResource.currentValue.search.indexName;
        this.type = changedObjects.jobResource.currentValue.search.indexType;

        this.assembly = changedObjects.jobResource.currentValue.assembly;

        // TODO: split into separate function / service
        this.synonyms = changedObjects.jobResource.currentValue.search.synonyms;

        this.synonymsLowerCase = {};

        if (this.synonyms) {
          Object.keys(this.synonyms).forEach(name => {
            this.synonymsLowerCase[name.toLowerCase()] = this.synonyms[name];
          });
        }

        if (!changedObjects.jobResource.currentValue.config) {
          $log.error("Require job to have a config property");
        } else {
          this.jobConfig = JSON.parse(
            changedObjects.jobResource.currentValue.config
          );

          // in newest beta, tracks top-level object will have inner "tracks" property
          // while the parent has some global tracks configs
          this.tracks =
            (this.jobConfig.tracks && this.jobConfig.tracks.tracks) ||
            this.jobConfig.tracks;
        }

        if (changedObjects.jobResource.currentValue.search.indexConfig) {
          // copy last array item
          indexConfig = changedObjects.jobResource.currentValue.search.indexConfig.slice(
            -1
          )[0];

          // We now store this as JSON to avoid Mongo '.' issues in field names
          if (typeof indexConfig === "string") {
            indexConfig = JSON.parse(indexConfig);
          }

          Object.keys(indexConfig.mappings.properties).forEach(field => {
            searchFields.getMapping(
              indexConfig.mappings.properties[field],
              field,
              this.allSearchFields,
              this.allExactSearchFields,
              this.allExactSearchFieldsMinimal,
              this.parentFields,
              this.numericalFields,
              this.booleanFields
            );
          });

          Object.keys(this.numericalFields).forEach(field => {
            if (field === "trTv") {
              this.hasTrTvField = true;
            }
          });

          this.mapping.forEach(key => {
            if (key in this.allExactSearchFieldsMinimal) {
              this.orderedExactSearchFieldsMinimal.push([
                key,
                this.allExactSearchFieldsMinimal[key]
              ]);
            } else if (
              key in this.numericalFields ||
              key in this.booleanFields
            ) {
              this.orderedExactSearchFieldsMinimal.push([key, key]);
            }
          });

          Object.keys(this.allSearchFields).forEach(val => {
            if (searchFields.defaultFieldsNotSearched[val]) {
              // next;
              return;
            }

            if (searchFields.defaultBoost[val]) {
              this.defaultSearchFieldsWithBoost.push(
                `${val}^${searchFields.defaultBoost[val]}`
              );
            } else {
              this.defaultSearchFieldsWithBoost.push(val);
            }

            this.defaultSearchFields.push(val);
          });

          // $log.debug('defaultSearchExactFieldsList', defaultSearchExactFieldsList);
          // $log.debug('this.defaultSearchFields', this.defaultSearchFields, 'with boost', this.defaultSearchFieldsWithBoost);
          // default fields include chrom, which is not numeric, but cast to be
          // Concat will make the result single copy (so can order by placing some items in the first array being
          // concatenated on)
          this.sortableFields = [].concat(
            ["chrom", "pos"],
            Object.keys(this.numericalFields).sort()
          );

          sortModes = indexConfig.sort || {};
        }

        // Unique: http://stackoverflow.com/questions/1960473/unique-values-in-an-array
        this.sortableFields = this.sortableFields.filter((val, index, self) => {
          return self.indexOf(val) === index;
        });

        lowerCaseFields = {};
        Object.keys(this.allSearchFields).forEach(val => {
          lowerCaseFields[val.toLowerCase()] = val;
        });

        parentLowerCaseFields = {};
        Object.keys(this.parentFields).forEach(val => {
          parentLowerCaseFields[val.toLowerCase()] = val;
        });

        fuzzy = $window.FuzzySet(
          [].concat(
            Object.keys(lowerCaseFields),
            Object.keys(parentLowerCaseFields)
          ),
          true,
          3,
          8
        );

        //We default to auto trTv; but don't trigger a query, because we don't yet have one
        this.toggleTrTvAggregation(true);
      }
    }

    if (changedObjects.size) {
      if (changedObjects.size.currentValue) {
        this.recordsToFetch = parseInt(changedObjects.size.currentValue, 10);
      }
    }

    if (changedObjects.sort) {
      if (changedObjects.sort.currentValue) {
        this.sortQueries = JSON.parse(changedObjects.sort.currentValue);
        this.getAllSortCounts();
      }
    }

    if (changedObjects.from) {
      if (changedObjects.from.currentValue) {
        this.fromRecord = parseInt(changedObjects.from.currentValue, 10);
        this.page = this.fromRecord / this.recordsToFetch;
      }
    }

    // Must come last, because modifies other properties
    if (changedObjects.query) {
      if (changedObjects.query.currentValue) {
        // We previously had a query, so this is a brand new search
        if (this.transformedQuery) {
          this.noResults = false;
          this.foundLastPage = false;
          this.fromRecord = 0;
          this.page = 0;
        }

        this.searchInput(changedObjects.query.currentValue);
      }
    }
  };

  // TODO: move all of the regex stuff into separate comonent

  const _hgvsRegex = (match, refAllele, codonNumber, otherAllele) => {
    if (otherAllele === "*") {
      return `refSeq.codonNumber:${codonNumber} refSeq.refAminoAcid:${refAllele} refSeq.altCodon:(tga || taa || tag)`;
    }

    return `refSeq.codonNumber:${codonNumber} refSeq.refAminoAcid:${refAllele} refSeq.altAminoAcid:${otherAllele}`;
  };

  const _fieldRegex = (match, fieldName, maybePeriodStar) => {
    // Either the user gave us a field name, or some random string
    let lcField = fieldName.toLowerCase();
    let star = maybePeriodStar;

    if (lcField.charAt(fieldName.length - 1) === ".") {
      lcField = lcField.replace(/\.$/, "");
    }

    let updatedFieldName;

    if (parentLowerCaseFields[lcField]) {
      // star = true;
      lcField = parentLowerCaseFields[lcField];
    } else {
      updatedFieldName =
        lcField === "_exists_" ? lcField : lowerCaseFields[lcField];

      if (!updatedFieldName) {
        if (this.didYouMean[lcField] === undefined) {
          this.didYouMean[lcField] = fuzzy.get(lcField);
        }

        if (this.didYouMeanOrder.indexOf(lcField) === -1) {
          this.didYouMeanOrder.push(lcField);
        }

        // If it's not a real field name, the colon is part of the string (or the user gave us a wrong query)
        return match;
      }
    }

    if (this.didYouMean[lcField]) {
      // this.didYouMean[updatedField] = this.didYouMean[fieldName];
      delete this.didYouMean[lcField];
      this.didYouMeanOrder.splice(this.didYouMeanOrder.indexOf(lcField), 1);
    }

    // Escape the star, as required by the elastic query dsl
    return `${updatedFieldName || lcField}${star ? ".\\*" : ""}:`;
  };

  function addslashes(str) {
    return (str + "").replace(/[\\"']/g, "\\$&").replace(/\u0000/g, "\\0");
  }

  // If the user supplied something like "cadd > 20, convert that to cadd:>20,
  // which is elasticSearch compatible"
  const _scoreRegex = (
    match,
    fieldName,
    colon,
    infix,
    number,
    count,
    original
  ) => {
    // $log.debug('score regex got', match, fieldName, infix, number, count, original);
    if (!colon && !infix) {
      return match;
    }

    const lcField = fieldName.toLowerCase();

    if (lcField === "maf") {
      return (
        "(" +
        _scoreRegex(
          match,
          "gnomad.genomes.af",
          colon,
          infix,
          number,
          count,
          original
        ) +
        " || " +
        _scoreRegex(
          match,
          "gnomad.exomes.af",
          colon,
          infix,
          number,
          count,
          original
        ) +
        ")"
      );

      // if(hasGnomad) {

      // }

      // return
    }

    if (this.numericalFields[lowerCaseFields[lcField]]) {
      if (infix) {
        if (infix === "==" || infix === "=") {
          infix = "";
        } else if (infix === "=>") {
          infix = ">=";
        } else if (infix === "=<") {
          infix = "<=";
        }
      }

      if (number.match(/\d+\.{2,4}\d+/)) {
        const numbers = number.split(/\.{2,4}/);
        return _scoreRangeRegex(
          null,
          lowerCaseFields[lcField],
          "[",
          numbers[0],
          " TO ",
          numbers[1],
          "]"
        );
      }

      return `${lowerCaseFields[lcField]}:${infix || ""}${_formatNumber(
        number
      )}`;
    }

    if (!this.didYouMean[lcField]) {
      this.didYouMean[lcField] = fuzzy.get(lcField);
      if (!this.didYouMean[lcField]) {
        delete this.didYouMean[lcField];
      } else {
        this.didYouMeanOrder.push(lcField);
      }
    }

    return match;
  };

  const _scoreRangeRegex = (
    match,
    fieldName,
    optionalBracket,
    firstNumber,
    infix,
    secondNumber,
    optionalBracket2,
    count,
    original
  ) => {
    // $log.debug('in _scoreRangeRegex got', fieldName, firstNumber, infix, secondNumber, count, original);
    let lcField = fieldName.toLowerCase();

    if (lcField === "maf") {
      return (
        "(" +
        _scoreRangeRegex(
          match,
          "gnomad.genomes.af",
          optionalBracket,
          firstNumber,
          infix,
          secondNumber,
          optionalBracket2,
          count,
          original
        ) +
        " || " +
        _scoreRangeRegex(
          match,
          "gnomad.exomes.af",
          optionalBracket,
          firstNumber,
          infix,
          secondNumber,
          optionalBracket2,
          count,
          original
        ) +
        ")"
      );

      // if(hasGnomad) {

      // }

      // return
    }

    if (this.numericalFields[lowerCaseFields[lcField]]) {
      return `${lowerCaseFields[lcField]}:[${_formatNumber(
        firstNumber
      )} TO ${_formatNumber(secondNumber)}${optionalBracket2 || "]"}`;
    }

    if (!this.didYouMean[lcField]) {
      this.didYouMean[lcField] = fuzzy.get(lcField);
      if (!this.didYouMean[lcField]) {
        delete this.didYouMean[lcField];
      } else {
        this.didYouMeanOrder.push(lcField);
      }
    }

    return match;
  };

  function _makeQueryFromString(userQuery) {
    explain = 0;
    let query = userQuery;
    let fields;

    this.didYouMean = {};
    this.didYouMeanOrder = [];

    if (query.indexOf("--explain") > -1) {
      query = query.replace(/--explain/, "");
      explain = 1;
    }

    // TODO: improve this
    if (this.synonyms) {
      const synonyms = Object.keys(this.synonyms).sort(
        (a, b) => b.length - a.length
      );

      for (const synonym of synonyms) {
        const val = this.synonyms[synonym];
        // const re = new RegExp(`${synonym}`, "gi");
        query = query.replace(new RegExp(synonym, 'g'), () => {
          if (Array.isArray(val)) {
            return `( ${val.join(" OR ")} )`;
          }

          return val;
        });
      }
    }

    // query = query.replace(/([a-zA-Z_]+?)?(\d+\.{0,1}\d*e\d+|\d+\.{0,1}\d*)([a-zA-Z_-]+?)?/g, _numberRegex);
    // query = query.replace(/\b(maf)\s*([:<=>]+)/gi, (match, maf, infix) => defaultMafField + infix);
    // query = query.replace(/([a-zA-Z_]+?)?(\d+\.{0,1}\d*e\d+|\d+\.{0,1}\d*)([a-zA-Z_-]+?)?/g, _numberRegex);
    // query = query.replace(/^(maf)\s*([:<=>]+)/gi, (match, maf, infix) =>  defaultMafField + infix);

    // Needs to come after all other transformations that could modify the
    // fieldName specified before the :
    query = query.replace(
      /\b(het|hets|hom|homs|homo):/g,
      (match, fieldName) => {
        if (fieldName.indexOf("het") > -1) {
          return "heterozygotes:";
        }

        return "homozygotes:";
      }
    );

    // TODO: make chrom stuff case indpendent
    query = query.replace(/\b(chr)\s*:(\S+)/g, (match, chr, value) => {
      if (value.indexOf("chr") > -1) {
        return `chrom:${value}`;
      }

      return `chrom:chr${value}`;
    });

    query = query.replace(
      /\b(chr[\w_]+)\s*:(\s*\[)?\s*([\de,\.+-]+)(\s*\-\s*|\s*TO\s*|\s*[\.]{2,4}\s*)([\de,\.+-]+)(\s*\])?/g,
      _chrScoreRangeRegex
    );
    query = query.replace(
      /\b([\.\w]+):(\s*\[)?\s*([\de,\.+-]+)(\s*\-\s*|\s*TO\s*|\s*[\.]{2,4}\s*)([\de,\.+-]+)(\s*\])?/g,
      _scoreRangeRegex
    );
    query = query.replace(
      /\b(chr[\w_]+)\s*([:>=<]{1,3})\s*([\de,\.+-]+)/g,
      _chrScoreRegex
    );
    query = query.replace(
      /\b([\.\w]+)\s*(:{0,1})\s*([>=<]{0,2})\s*([\de,\.+-]+)/g,
      _scoreRegex
    );

    //Javascript doesn't support character class subtraction, mimic
    // If i include \< get "Unexpected escaped character '<' in regular expression."
    // < > are a security issue
    query = query.replace(
      /\b(sample[s]{0,1}:\s*)([\(]{1}[\w\d\;\:\\\'\"\/\.\,\?\{\}\[\]\|\-\_\+\=\*\&\^\%\$\#\@\!\~\`\s]+[\)]{1}|[\w\d\;\:\\\'\"\/\.\,\?\{\}\[\]\|\-\_\+\=\*\&\^\%\$\#\@\!\~\`]+)/g,
      (match, sampleAlias, value) => {
        return `(heterozygotes:${value} OR homozygotes:${value} OR missingGenos:${value})`;
      }
    );

    query = query.replace(
      /([\(]+)?(doesn\'t\s+|doesnt\s+|not\s+|\!\s{0,1}|\-\s{0,1})?(no|not|in|without|have|with|has|contains|contain|_exists_|_missing_|missing|exists|including|include|includes|exclude|excludes|excluding)?(\\\:|\:|\s*)([\.\w]+)([\)]+)?(\S+)?/gi,
      (
        match,
        oParenLeft,
        oNegator,
        existsMatch,
        colonMatch,
        fieldMatch,
        oParenRight,
        oTherStuff,
        full
      ) => {
        const fieldName =
          lowerCaseFields[fieldMatch.toLowerCase()] ||
          parentLowerCaseFields[fieldMatch.toLowerCase()];

        if (!fieldName || oTherStuff) {
          return match;
        }

        let negator;

        if (oNegator) {
          negator = oNegator.toLowerCase().trim();

          if (
            negator === "not" ||
            negator === "doesn't" ||
            negator === "doesnt" ||
            negator === "!" ||
            negator === "-"
          ) {
            negator = "-";
          } else {
            negator = null;
          }
        }

        let exists;
        existsMatch = existsMatch ? existsMatch.toLowerCase() : null;

        if (!existsMatch) {
          if (negator) {
            exists = "_exists_";
          } else {
            // nothing
            return match;
          }
        } else if (
          existsMatch === "_missing_" ||
          existsMatch === "missing" ||
          existsMatch === "exclude" ||
          existsMatch === "excludes" ||
          existsMatch === "excluding" ||
          existsMatch === "without" ||
          existsMatch === "not" ||
          existsMatch === "no"
        ) {
          if (negator) {
            exists = "_exists_";
            negator = null;
          } else {
            exists = "-_exists_";
          }
        } else {
          exists = "_exists_";
        }

        let newParens = existsMatch != "_exists_";

        return `${oParenLeft || ""}${newParens ? "(" : ""}${negator ||
          ""}${exists}:${fieldName}${newParens ? ")" : ""}${oParenRight || ""}`;
      }
    );

    // the _fieldRegex must come after anything that we want transformed to a specific field
    // like samples above
    // Modifies this.didYouMean
    query = query.replace(
      /\b([\.\w]+)(\\{0,1}\*){0,1}(\s*[:><=])/g,
      _fieldRegex
    );

    // searches fail when they have ';' for some reason
    query = query.replace(/\s;\s/g, " ");

    query = query.replace(/\bp\.([a-zA-Z]+)(\d+)([a-zA-Z\*]+)/gi, _hgvsRegex);

    query = query.replace(
      /\b(allele[s]{0,1}|variant[s]{0,1}|mutant[s]{0,1}|mutation[s]{0,1}|variation[s]{0,1})\b/gi,
      ""
    );

    query = query.replace(
      /\b(lof|\"loss\sof\sfunction\"|loss\sof\sfunction"|loss-of-function)\b/gi,
      (match, lof, full) =>
        `(${lof} || stopGain || stopLoss || spliceDonor || spliceAcceptor || indel-frameshift)`
    );

    // Elasticsearch hates non-alphanumeric after precedence operator
    // Not sure what best solution here is; for now stripping commas because refseq descriptions
    // often contain a comma after a parenthesis
    query = query.replace(
      /(\))(\s)*([,]\s+)/g,
      (match, group1, spacer, group2) => {
        if (!spacer) {
          return group1 + " ";
        }

        return match;
      }
    );

    // TODO: Skip this if user gives quotes
    const parts = query.split(" ");
    const lastWord = parts[parts.length - 1];
    // const didYouMeanTerms = {};

    // We return defaultSearchFields becuase elasticsearch doesn't allow us
    // to exclude fields to search
    // And we don't want to search some by default
    return [query, fields || this.defaultSearchFields];
  }

  this.showMajorConsequences = index => {
    //let consequence = "":
    //const hasStuff = {};

    const refSeq = this.data.hits.hits[index]["_source"]["refSeq"];

    if (refSeq === undefined || !Object.keys(refSeq).length) {
      return "";
    }

    // For now just check the first record
    if (
      refSeq.siteType.length > 1 ||
      refSeq.siteType.length[0] > 1 ||
      Array.isArray(refSeq.siteType[0][0])
    ) {
      const uniq = {};
      const rVal = [];
      const isArrayInner = Array.isArray(refSeq.siteType[0][0]);

      for (let i = 0; i < refSeq.siteType.length; i++) {
        for (let j = 0; j < refSeq.siteType[0].length; j++) {
          if (isArrayInner) {
            for (let y = 0; y < refSeq.siteType[0][0].length; y++) {
              // If an indel spans intronic sites, we may get a simple null for codon-related fields
              // even though there are multiple site types
              const val = _getOutcome(
                refSeq.siteType[i][j][y],
                refSeq.codonNumber &&
                refSeq.codonNumber[i][j] &&
                refSeq.codonNumber[i][j][y],
                refSeq.refAminoAcid &&
                refSeq.refAminoAcid[i][j] &&
                refSeq.refAminoAcid[i][j][y],
                refSeq.altAminoAcid &&
                refSeq.altAminoAcid[i][j] &&
                refSeq.altAminoAcid[i][j][y],
                refSeq.exonicAlleleFunction &&
                refSeq.exonicAlleleFunction[i][j] &&
                refSeq.exonicAlleleFunction[i][j][y]
              );
              if (uniq[val]) {
                continue;
              }

              uniq[val] = 1;

              rVal.push(val);
            }
          } else {
            const val = _getOutcome(
              refSeq.siteType[i][j],
              refSeq.codonNumber && refSeq.codonNumber[i][j],
              refSeq.refAminoAcid && refSeq.refAminoAcid[i][j],
              refSeq.altAminoAcid && refSeq.altAminoAcid[i][j],
              refSeq.exonicAlleleFunction && refSeq.exonicAlleleFunction[i][j]
            );

            if (uniq[val]) {
              continue;
            }

            uniq[val] = 1;

            rVal.push(val);
          }
        }
      }

      return rVal.join("\n").trim();
    }

    // All values are stored to the same array depth when multiple values are present; if siteType isn't an array, none of the others will be either
    return _getOutcome(
      refSeq.siteType[0][0],
      refSeq.codonNumber && refSeq.codonNumber[0][0],
      refSeq.refAminoAcid && refSeq.refAminoAcid[0][0],
      refSeq.altAminoAcid && refSeq.altAminoAcid[0][0],
      refSeq.exonicAlleleFunction && refSeq.exonicAlleleFunction[0][0]
    );
  };

  function _getOutcome(siteType, codonNum, refAA, altAA, exonicAlleleFunc) {
    switch (siteType) {
      case "exonic":
        // This case should never happen, but does, see
        // IL23R
        // chr1 : 67,672,592
        // in gnomad (3 base insertion)

        if (refAA && codonNum !== undefined && altAA) {
          return `${refAA}${codonNum}${altAA || "-"}`;
        }

        return exonicAlleleFunc || siteType;
      default:
        return siteType;
    }
  }

  function _fillQueryResultsObject(data) {
    data.hits.hits.forEach(rowResultObj => {
      const resultObj = rowResultObj._source;
      const row = {};

      this.mapping.forEach(fieldName => {
        const path = {};

        if (fieldName.indexOf(".") === -1) {
          row[fieldName] = resultObj[fieldName];
        }

        const fieldPath = fieldName.split(".");

        row[fieldName] = _getPathValue(resultObj, fieldPath);
      });

      this.queryResults.push(row);
      // copy array
      let uniqRow = {};

      // NEED TO ACCOUNT FOR CASE WHERE the unique inner array values
      // are identical, leading to nonSynonymous | nonSynonymous
      Object.keys(row).forEach(fieldName => {
        // Each value is set per allele
        if (row[fieldName] === emptyFieldChar) {
          return;
        }

        // const isDbSNP = fieldName.indexOf('dbSNP') > -1;
        // const isPhyloP = fieldName.indexOf('phyloP') > -1;
        // const isPhastCons = fieldName.indexOf('phastCons') > -1;
        // const isCadd = fieldName.indexOf('cadd') > -1;

        const val = row[fieldName].map(alleleVal => {
          // The top level value of any field is either empty, or is an array
          if (alleleVal === null) {
            return emptyFieldChar;
          }

          const preUniq = alleleVal.map(positionVal => {
            if (Array.isArray(positionVal)) {
              let uniq = {};
              let uniqueVal = [];

              positionVal.forEach(val => {
                if (
                  fieldName === "refSeq.name2" ||
                  fieldName === "refSeq.nearest.name2"
                ) {
                  if (!uniq[val]) {
                    uniq[val] = 1;
                    if (val !== null) {
                      uniqueVal.push(val);
                    } else {
                      console.info("is null", fieldName);
                    }
                  }
                } else {
                  uniqueVal.push(
                    emptyFieldChar !== null ? val : emptyFieldChar
                  );
                }
              });

              const noValue =
                !uniqueVal.length || uniqueVal.every(v => v === null);
              const allIdentical = uniqueVal.every(v => v === uniqueVal[0]);

              if (noValue) {
                return emptyFieldChar;
              }

              let out;
              if (allIdentical) {
                out = uniqueVal[0];
              } else {
                out = uniqueVal.join(valueDelimiter);
              }

              return out;
            }

            return positionVal !== null ? positionVal : emptyFieldChar;
          });

          return _.uniq(preUniq).join(positionDelimiter);
        });

        const joined = _.uniq(val).join(alleleDelimiter);

        if (joined === "" || joined === emptyFieldChar) {
          return;
        }

        uniqRow[fieldName] = joined;
      });

      this.queryResultsUnique.push(uniqRow);
    });
  }

  function _formatNumber(num) {
    num = num.trim();

    num = num.replace(/e(\d+)/g, (match, gr1) => {
      return `e+${gr1}`;
    });

    return numeral(num).value();
  }

  function _getPathValue(resultObj, pathArray) {
    let resultData = Object.assign({}, resultObj);

    let found = 0;
    pathArray.forEach(fieldName => {
      if (resultData.hasOwnProperty(fieldName)) {
        found++;
        resultData = resultData[fieldName];
      }
    });

    if (found === pathArray.length) {
      return resultData;
    }

    return emptyFieldChar;
  }

  function _chrScoreRangeRegex(
    match,
    chr,
    optionalBracket,
    firstNumber,
    infix,
    secondNumber,
    optionalBracket2,
    count,
    original
  ) {
    if (chr.toLowerCase().indexOf("chrom") > -1) {
      return match;
    }

    return `(chrom:${chr} pos:[${firstNumber} TO ${secondNumber}${optionalBracket2 ||
      "]"})`;
  }

  function _chrScoreRegex(match, chr, infix, position) {
    $log.debug("chrScoreRegex got", arguments);
    if (chr.toLowerCase().indexOf("chrom") > -1) {
      return match;
    }

    let noColonInfix = infix.replace(/:/g, "");

    if (noColonInfix.indexOf(">") === -1 && noColonInfix.indexOf("<") === -1) {
      noColonInfix = noColonInfix.replace(/=/g, "");
    } else if (noColonInfix === "=>") {
      noColonInfix = ">=";
    } else if (noColonInfix === "=<") {
      noColonInfix = "<=";
    }

    if (position.indexOf(",") > -1) {
      position = position.replace(/,/g, "");
    }

    return `(chrom:${chr} pos:${noColonInfix}${position})`;
  }
}

angular
  .module("sq.jobs.results.search.component", [
    "sq.jobs.results.search.refSeq.component",
    "sq.jobs.download.directive",
    "sq.jobs.results.search.pipelines.component",
    "sq.jobs.results.search.save.component",
    "sq.jobs.search.fields.service",
    "sq.jobs.results.search.fullCard.component",
    "sq.user.profile.service" /*, 'sq.jobs.search.chi2.service'*/
  ])
  .filter("keyboardShortcut", $window => {
    return str => {
      if (!str) {
        return;
      }

      const keys = str.split("-");
      const isOSX = /Mac OS X/.test($window.navigator.userAgent);

      const seperator = !isOSX || keys.length > 2 ? "+" : "";

      const abbreviations = {
        M: isOSX ? "⌘" : "Ctrl",
        A: isOSX ? "Option" : "Alt",
        S: "Shift"
      };

      return keys
        .map((key, index) => {
          const last = index === keys.length - 1;
          return last ? key : abbreviations[key];
        })
        .join(seperator);
    };
  })
  .component("sqSearch", {
    bindings: {
      jobResource: "<",
      query: "<",
      sort: "<",
      size: "<",
      from: "<",
      update: "&",
      onTransform: "&",
      onSuggested: "&",
      onMissing: "&"
    }, // isolate scope
    templateUrl: "jobs/results/search/jobs.results.search.tpl.html",
    controller: ElasticSearchController
  });
